<template>
    <div class="hotnavs animate__animated animate__fadeIn">
        <a-row :gutter="16" class="nav-bars">
            <a-col :span="8">
                <div class="box nav-bar" @click="$router.push({ name: 'tinjiku' })">
                    <img class="icon" src="@/assets/imgs/icons/01.png" alt="">
                    <span>{{ $t('home.navTitles.tinjiku') }}</span>
                </div>
            </a-col>
            <a-col :span="8">
                <div class="box nav-bar" @click="$router.push({ name: 'swap' })">
                    <img class="icon" src="@/assets/svgs/bless-filled.svg" alt="">
                    <span>{{ $t('home.navTitles.bct') }}</span>
                </div>
            </a-col>
            <a-col :span="8">
                <div class="box nav-bar" @click="$router.push({name:'gongxiu'})">
                    <img class="icon" src="@/assets/imgs/icons/03.png" alt="">
                    <span>{{ $t('home.navTitles.gongxiu') }}</span>
                </div>
            </a-col>

            <a-col :span="8">
                <div class="box nav-bar" @click="$router.push({name:'signin'})">
                    <img class="icon" src="@/assets/svgs/sp.svg" alt="">
                    <span>{{ $t('home.navTitles.signin') }}</span>
                </div>
            </a-col>

            <a-col :span="8">
                <div class="box nav-bar" @click="$router.push({name:'budhi'})">
                    <img class="icon" src="@/assets/svgs/ground.svg" alt="">
                    <span>{{ $t('home.navTitles.budhi') }}</span>
                </div>
            </a-col>
            <a-col :span="8">
                <div class="box nav-bar" @click="$router.push({name:'tasks'})">
                    <img class="icon" src="@/assets/imgs/icons/04.png" alt="">
                    <span>{{ $t('home.navTitles.faxin') }}</span>
                </div>
            </a-col>
            <a-col :span="8">
                <div class="box nav-bar" @click="$router.push({name: 'stake'})">
                    <img class="icon" src="@/assets/imgs/icons/05b.png" alt="">
                    <span>{{ $t('home.navTitles.stake') }}</span>
                </div>
            </a-col>
            <!-- <a-col :span="8">
                <div class="box nav-bar closed" @click="commingSoon()">
                    <img class="icon" src="@/assets/imgs/icons/02.png" alt="">
                    <span>{{ $t('home.navTitles.defi') }}</span>
                </div>
            </a-col>
            
            <a-col :span="8">
                <div class="box nav-bar closed" @click="commingSoon()">
                    <img class="icon" src="@/assets/imgs/icons/06.png" alt="">
                    <span>{{ $t('home.navTitles.game') }}</span>
                </div>
            </a-col>
            <a-col :span="8">
                <div class="box nav-bar closed" @click="commingSoon()">
                    <img class="icon" src="@/assets/imgs/icons/07.png" alt="">
                    <span>{{ $t('home.navTitles.nfts') }}</span>
                </div>
            </a-col> -->
            <a-col :span="8">
                <div class="box nav-bar closed" @click="commingSoon()">
                    <img class="icon" src="@/assets/imgs/icons/08.png" alt="">
                    <span>{{ $t('home.navTitles.exchange') }}</span>
                </div>
            </a-col>
            <a-col :span="8">
                <div class="box nav-bar closed" @click="commingSoon()">
                    <img class="icon" src="@/assets/imgs/icons/09.png" alt="">
                    <span>{{ $t('home.navTitles.global') }}</span>
                </div>
            </a-col>
        </a-row>
    </div>

</template>
<script>
export default {
    name: "Hotnavs",
    data() {
        return {

        };
    },
    props: {
        imgList: {
            type: Array
        }
    },
    methods: {
        commingSoon() {
            this.$message.info(this.$t('common.commingsoon'));
        },
    },
};
</script>
<style lang="less" scoped>
.hotnavs {
    .nav-bars {
        display: flex;
        flex-wrap: wrap;
        .nav-bar {
            box-shadow: 1PX 1PX 4PX #ffffff;
            color: @primary-color;
            padding: 20PX;
            margin: 20PX auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .icon {
                padding-bottom: 7PX;
                opacity: .8;
                height: 38PX;
            }
        }.closed{
            opacity: .8;
            // background: #dadada;
            // color: #777;
        }
    }
}
</style>
