<template>
    <div class="home">
        <Logo />
        <carousel class="carousel" :imgList="carouselImgList"></carousel>
        <hotnavs class="hotnavs" />
        <Nav />
    </div>
</template>
<script>
import Logo from "../components/Logo.vue";
import Carousel from "../components/Carousel.vue"
import Hotnavs from "@/components/Hotnavs.vue"
import Nav from "../components/Nav.vue";
export default {
    name: "Home",
    data() {
        return {
            carouselImgList: [
                {url: '/statics/imgs/banners/01.jpg'},
                {url: '/statics/imgs/banners/02.jpg'},
                {url: '/statics/imgs/banners/03.jpg'},
            ]            
        };
    },
    components: {
        Carousel, Hotnavs, Nav, Logo
    },
    mounted() {
        
    },
    methods: {
        
    },
};
</script>
<style lang="less" scoped>
.home {
    width:100%;
    padding: 0 50px;
    padding-bottom: 168px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    .carousel{
        background: transparent;
        border-radius: 30px;
    }
    .hotnavs {
        flex: 1;
        overflow-y: scroll;
        overflow-x: hidden;;
    }
}
</style>
