<template>
  <a-carousel :after-change="onChange" :autoplay="true">
    <div class="item" v-for="img,i in imgList" :key="'i'+i">
      <img :src="img.url" alt="">
    </div>
  </a-carousel>
</template>
<script>
export default {
  name: "Carousel",
  data() {
      return {
          
      };
  },
  props: {
    imgList:{
      type: Array
    }
  },
  methods: {
    onChange() {
    },
  },
};
</script>
<style scoped>
.ant-carousel >>> .slick-slide {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  
}
.item {
  border-radius: 50px;
  overflow: hidden;
}
.item img{
  width: 100%;
}
.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
.ant-carousel >>> li.slick-active button{
  /* background: #f78a3a; */
}
</style>
